import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  ChipField,
  SingleFieldList,
  ArrayField,
  BooleanField,
  EditButton,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';
import TextArrayField from 'components/fields/TextArrayField';

const DeployList = () => (
  <List>
    <Datagrid>
      <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
      <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
      <TextField source="title" label="Title" />
      <TextField source="githubOrg" label="Github Organization" />
      <BooleanField source="enabled" label="Enabled" />
      <TextArrayField source="projects">
        <SingleFieldList>
          <ChipField source="id" />
        </SingleFieldList>
      </TextArrayField>
      <ArrayField source="channels">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

export default DeployList;
