import { ExplorerProvider } from 'providers/ExplorerProvider';
import React from 'react';
import {
  Edit,
} from 'react-admin';
import EditForm from './EditForm';

const DeployEdit = () => (
  <Edit>
    <ExplorerProvider>
      <EditForm />
    </ExplorerProvider>
  </Edit>
);

export default DeployEdit;
