import { Stack } from '@mui/material';
import React, { useMemo } from 'react';
import { get } from 'lodash';

import {
  TextInput,
  SelectInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  PasswordInput,
  TabbedForm,
  FormTab,
  AutocompleteArrayInput,
} from 'react-admin';
import {
  CredentialsFormListener,
  ExploredResourceList,
  useExplorer,
} from 'providers/ExplorerProvider';

const jiraFormDependencies = ['credentials.JIRA_BASE_URL', 'credentials.JIRA_API_USER', 'credentials.JIRA_API_TOKEN'];

const extractJiraCredentials = (values) => {
  const baseUrl = get(values, 'credentials.JIRA_BASE_URL');
  const apiUser = get(values, 'credentials.JIRA_API_USER');
  const apiToken = get(values, 'credentials.JIRA_API_TOKEN');
  const credentials = { baseUrl, apiToken, apiUser };
  return credentials;
};
const githubFormDependencies = ['credentials.GITHUB_TOKEN', 'githubOrg'];

const extractGithubCredentials = (values) => {
  const githubToken = get(values, 'credentials.GITHUB_TOKEN');
  const githubOrg = get(values, 'githubOrg');
  return { githubToken, githubOrg };
};

const EditFormInner = () => {
  const explorerCtx = useExplorer();
  const { exploredData } = explorerCtx || {};
  const settingsActive = exploredData?.jira && exploredData?.github;
  const projectOptions = useMemo(() => (exploredData?.jira || []).map((project) => ({
    name: project?.title,
    id: project?.item?.key,
  })), [exploredData?.jira]);

  const repoOptions = useMemo(() => (exploredData?.github || []).map((repo) => ({
    name: repo?.title,
    id: repo?.item?.name,
  })), [exploredData?.github]);

  return (
    <TabbedForm>
      <FormTab label="Credentials">
        <Stack direction="row" spacing={2}>
          <Stack spacing={2}>
            <TextInput source="githubOrg" />
            <PasswordInput source="credentials.GITHUB_TOKEN" label="Github Token" />
            <TextInput source="credentials.JIRA_BASE_URL" label="Jira URL" />
            <TextInput source="credentials.JIRA_API_USER" label="Jira API User" />
            <PasswordInput source="credentials.JIRA_API_TOKEN" label="Jira API Token" />
            <CredentialsFormListener
              resourceKey="jira"
              extractCredentials={extractJiraCredentials}
              formDependencies={jiraFormDependencies}
            />
            <CredentialsFormListener
              resourceKey="github"
              extractCredentials={extractGithubCredentials}
              formDependencies={githubFormDependencies}
            />
          </Stack>
          <ExploredResourceList resourceKey="github" title="Github preview" />
          <ExploredResourceList resourceKey="jira" title="Jira preview" />
        </Stack>
      </FormTab>
      <FormTab label="General Settings" disabled={!settingsActive}>
        <Stack spacing={2} direction="row" alignItems="center">
          <TextInput required source="title" />
          <TextInput required source="projectKey" />
          <AutocompleteArrayInput source="projects" choices={projectOptions} />
          <BooleanInput source="enabled" />
        </Stack>
        <Stack p={2} spacing={2} width="100%" direction="row">
          <Stack spacing={2} minWidth="100px">
            <ArrayInput source="repos">
              <SimpleFormIterator inline>
                <SelectInput source="repo" choices={repoOptions} />
                <SelectInput source="repoExplained" choices={repoOptions} />
              </SimpleFormIterator>
            </ArrayInput>
          </Stack>
          <Stack spacing={2} minWidth="100px">
            <ArrayInput source="projectList">
              <SimpleFormIterator inline>
                <SelectInput source="projectKey" choices={projectOptions} />
                <BooleanInput source="hasProjectLabel" />
                <TextInput source="projectLabel" />
              </SimpleFormIterator>
            </ArrayInput>
          </Stack>
        </Stack>
      </FormTab>
      <FormTab label="Slack Integration" disabled={!settingsActive}>
        <Stack p={2} spacing={2} width="100%" direction="row">
          <Stack spacing={2}>
            <ArrayInput source="channels">
              <SimpleFormIterator inline>
                <TextInput source="name" />
              </SimpleFormIterator>
            </ArrayInput>
          </Stack>
        </Stack>
      </FormTab>
    </TabbedForm>
  );
};

export default EditFormInner;
