import React, { useMemo } from 'react';

import {
  Admin,
  Resource,
  CustomRoutes,
  fetchUtils,
} from 'react-admin';
import { Route } from 'react-router';
import './AdminContainer.scss';
import { useAuthContext, usePermissions } from 'providers/AuthProvider';
import jsonServerProvider from 'ra-data-json-server';

import { getDeployerApiUrl } from 'utils/api';
import DeployList from 'components/resources/Deploy/List';
import DeployShow from 'components/resources/Deploy/Show';
import DeployCreate from 'components/resources/Deploy/Create';
import DeployEdit from 'components/resources/Deploy/Edit';

import { Alert } from '@mui/material';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    // eslint-disable-next-line no-param-reassign
    options.headers = new window.Headers({ Accept: 'application/json' });
  }
  options.headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(getDeployerApiUrl(), httpClient);

const AdminContainer = () => {
  const authCtx = useAuthContext();

  const permissions = usePermissions();

  if (!permissions.length) {
    return (
      <Alert>Forbidden</Alert>
    );
  }

  const AvailableComponents = useMemo(() => [
    { component: DeployList, permissions: ['view'], key: 'DeployList' },
    { component: DeployShow, permissions: ['view'], key: 'DeployShow' },
    { component: DeployCreate, permissions: ['view', 'edit'], key: 'DeployCreate' },
    { component: DeployEdit, permissions: ['view', 'edit'], key: 'DeployEdit' },
  ].reduce((acc, item) => {
    const allowed = item.permissions.every((permissionItem) => permissions.includes(permissionItem));
    if (!allowed) {
      return acc;
    }
    return {
      ...acc,
      [item.key]: item.component,
    };
  }, {}), [permissions]);

  return (
    <Admin
      basename="/app"
      authProvider={authCtx.raAuthProvider}
      dataProvider={dataProvider}
    >
      <Resource
        name="deploys"
        list={AvailableComponents.DeployList}
        show={AvailableComponents.DeployShow}
        create={AvailableComponents.DeployCreate}
        edit={AvailableComponents.DeployEdit}
      />
      <CustomRoutes>
        <Route path="/my-profile" element={<div>My Profile</div>} />
      </CustomRoutes>
    </Admin>
  );
};

export default AdminContainer;
