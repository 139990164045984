import { ExplorerProvider } from 'providers/ExplorerProvider';
import React from 'react';
import {
  Create,
} from 'react-admin';
import EditForm from './EditForm';

const DeployCreate = () => (
  <Create>
    <ExplorerProvider>
      <EditForm />
    </ExplorerProvider>
  </Create>
);

export default DeployCreate;
