import { Chip } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';

const TextArrayField = ({ source }) => {
  const record = useRecordContext();
  const array = record[source];
  if (typeof array === 'undefined' || array === null || array.length === 0) {
    return <div />;
  }
  return (
    <>
      {array.map((item) => <Chip label={item} key={item} />)}
    </>
  );
};

TextArrayField.propTypes = {
  source: PropTypes.string.isRequired,
};

export default TextArrayField;
